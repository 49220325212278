import { DEFAULT_EXP_DATE } from '../constants/defaultFormValues'

export function prepareOrderDetailsForUpdate(orderDetails) {
    return {
        brand: orderDetails.brand,
        vin: orderDetails.vin || null,
        commissionNumber: orderDetails.commissionNumber || null,
        deliveryDate: orderDetails.deliveryDate,
        preferredServicePartnerId: orderDetails.preferredServicePartnerId,
        preferredServicePartnerName: orderDetails.preferredServicePartnerName,
        dealer: orderDetails.dealer,
    }
}

export function prepareCustomerInformationForUpdate(customerInformation) {
    return {
        email: customerInformation.email,
        firstName: customerInformation.firstName,
        surname: customerInformation.surname,
        nickname: customerInformation.nickname,
        dateOfBirth: customerInformation.dateOfBirth || null,
        address: customerInformation.address,
        preferredLanguage: customerInformation.preferredLanguage,
        phoneNumber: customerInformation.phoneNumber || null,
        dialCode: customerInformation.dialCode || null,
        lastModifiedDate: customerInformation.lastModifiedDate,
    }
}

export function prepareSeatIdentForUpdate(seatIdent) {
    return {
        licenseNumber: seatIdent.licenseNumber,
        issueDate: seatIdent.issueDate,
        expirationDate: seatIdent.expirationDate,
        countryOfIssueCode: seatIdent.countryOfIssueCode,
        dateOfBirth: seatIdent.dateOfBirth,
    }
}

export function prepareCustomerForCreate(customer, servicePartners, accountCheck, dealer) {
    let pspName = customer?.orderDetails?.preferredServicePartnerName
    if (!pspName) {
        const psp =
            servicePartners.length === 0
                ? null
                : servicePartners.find(
                      (s) => s.id === customer.orderDetails.preferredServicePartnerId
                  )
        if (psp === undefined) {
            pspName = servicePartners.find(
                (s) => s.value === customer.orderDetails.preferredServicePartnerId
            ).name
        } else if (psp === null) {
            pspName = null
        } else {
            pspName =
                psp.name +
                ', ' +
                psp.address.street +
                ', ' +
                psp.address.city +
                ', ' +
                psp.address.zip
        }
    }

    return {
        customerInformation: {
            email: customer.customerInformation.email,
            firstName: customer.customerInformation.firstName,
            surname: customer.customerInformation.surname,
            nickname: customer.customerInformation.nickname,
            dateOfBirth: customer.customerInformation.dateOfBirth || null,
            phoneNumber:
                customer.customerInformation.dialCode && customer.customerInformation.phoneNumber
                    ? customer.customerInformation.phoneNumber?.replace(/\s/g, '')
                    : null,
            dialCode:
                customer.customerInformation.dialCode && customer.customerInformation.phoneNumber
                    ? customer.customerInformation.dialCode
                    : null,
            preferredLanguage: customer.customerInformation.preferredLanguage,
            address: customer.customerInformation.address,
        },
        seatIdent: {
            licenseNumber: customer.seatIdent.licenseNumber,
            issueDate: customer.seatIdent.issueDate,
            expirationDate: customer.seatIdent.expirationDateCheckbox
                ? DEFAULT_EXP_DATE
                : customer.seatIdent.expirationDate,
            countryOfIssueCode: customer.seatIdent.countryOfIssueCode,
        },
        orderDetails: {
            commissionNumber: customer.orderDetails.commissionNumber || null,
            vin: customer.orderDetails.vin || null,
            deliveryDate: customer.orderDetails.deliveryDate,
            preferredServicePartnerId: customer.orderDetails.preferredServicePartnerId,
            preferredServicePartnerName: pspName,
            dealer: {
                id: dealer.username,
                firstName: dealer.firstName,
                lastName: dealer.lastName,
            },
            brand: customer.orderDetails.brand,
            createdByOcwData: !!customer.orderDetails.createdByOcwData,
        },
        seatConnectExistsFlag: accountCheck !== null ? accountCheck.connectAccount : false,
        seatIdentExistsFlag: accountCheck !== null ? accountCheck.seatIdAccount : false,
    }
}
