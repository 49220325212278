import { v4 as uuid4 } from 'uuid'
import { dateFormats, defaultCalendarFormat } from '../constants/dateFormats'
import { countryPhoneCodes } from '../constants/countryPhoneCodesConfiguration'
import { addressFieldsConfiguration } from '../constants/countriesAddressBuilderConfiguration'
import { NONE } from '../constants/defaultFormValues'
import { showCancelNewCustomer } from '../actions/popupsActions'
import {
    bgNewLocale,
    bsNewLocale,
    csNewLocale,
    daNewLocale,
    deNewLocale,
    elNewLocale,
    enNewLocale,
    esNewLocale,
    etNewLocale,
    fiNewLocale,
    frNewLocale,
    hrNewLocale,
    huNewLocale,
    isNewLocale,
    itNewLocale,
    ltNewLocale,
    lvNewLocale,
    mkNewLocale,
    nlNewLocale,
    noNewLocale,
    plNewLocale,
    ptNewLocale,
    roNewLocale,
    ruNewLocale,
    skNewLocale,
    slNewLocale,
    srNewLocale,
    svNewLocale,
    ukNewLocale,
} from './datepickerLocales'
import { ignoreKeyInHTML } from '../constants/global'

export const localeMap = {
    de: deNewLocale,
    en: enNewLocale,
    fr: frNewLocale,
    it: itNewLocale,
    bs: bsNewLocale,
    cs: csNewLocale,
    nl: nlNewLocale,
    bg: bgNewLocale,
    el: elNewLocale,
    da: daNewLocale,
    et: etNewLocale,
    ru: ruNewLocale,
    es: esNewLocale,
    fi: fiNewLocale,
    hr: hrNewLocale,
    hu: huNewLocale,
    is: isNewLocale,
    lt: ltNewLocale,
    lv: lvNewLocale,
    mk: mkNewLocale,
    nn: noNewLocale,
    pl: plNewLocale,
    pt: ptNewLocale,
    ro: roNewLocale,
    sr: srNewLocale,
    sv: svNewLocale,
    sl: slNewLocale,
    sk: skNewLocale,
    uk: ukNewLocale,
}

export function getCommissionNumberFromEmail(email, commissions) {
    const result = commissions.find((c) => c.email === email)
    if (result !== undefined) {
        return result.commissionNumber
    }
    return null
}

export function getCustomerFromEmail(email, commissions) {
    const result = commissions.find((c) => c.email === email)
    if (result !== undefined) {
        return result
    }
    return null
}

export function getDateFormat(language) {
    if (language === null) {
        return defaultCalendarFormat
    }
    language = language.substring(0, 2)
    return dateFormats[language]
}

export function isRegistrationProcess() {
    const pathname = window.location.pathname
    return (
        pathname === '/customer-information' ||
        pathname === '/seat-ident' ||
        pathname === '/order-details' ||
        pathname === '/consent-verification'
    )
}

export function getCustomerInformationOptions(countries, t) {
    const countryOptions = countries.map((c) =>
        t('general.country.' + c.countryCode, { replace: ignoreKeyInHTML })
    )
    countryOptions.unshift(t('general.select.choose', { replace: ignoreKeyInHTML }))
    const countryCodeOptions = countryPhoneCodes.map(
        (c) =>
            '(' + c.dial_code + ') ' + t('general.country.' + c.code, { replace: ignoreKeyInHTML })
    )
    countryCodeOptions.unshift(t('general.select.choose', { replace: ignoreKeyInHTML }))

    return {
        countryOptions,
        countryCodeOptions,
    }
}

export function getCustomerInformationAddressOptions(countries, t, data) {
    if (!data.address) {
        return null
    }
    const countryId = (
        data.address?.countryId ||
        (typeof data.country === 'string' ? data.country : data.country.id) ||
        '0'
    ).toString()
    if (!countryId) {
        return null
    }
    const foundCountry = countries.find((c) => c.id === countryId)
    if (foundCountry === undefined) {
        return null
    }
    const config = addressFieldsConfiguration[foundCountry.countryCode.toString()]
    let languageCodes = config.languageList
    const languages = languageCodes
        .filter((c) => c !== NONE)
        .map((c) => t('general.language.' + c, { replace: ignoreKeyInHTML }))
    if (languageCodes[0] !== NONE) {
        languageCodes.unshift(NONE)
    }
    if (languages[0] !== t('general.select.choose', { replace: ignoreKeyInHTML })) {
        languages.unshift(t('general.select.choose', { replace: ignoreKeyInHTML }))
    }
    const lines = countAddressLines(config.fields)

    return {
        lines,
        config,
        languageCodes,
        languages,
    }
}

export const countAddressLines = (fields) => {
    let linesCount = 0
    Object.entries(fields).forEach(([_k, v]) => {
        if (v.line && v.line > linesCount) {
            linesCount = v.line
        }
    })
    let lines = []
    for (let i = 0; i < linesCount; i++) {
        lines.push({})
    }
    Object.entries(fields).forEach(([k, v]) => {
        if (v.line) {
            lines[v.line - 1][k] = v
        }
    })
    return lines
}

export const getIndexForLanguage = (languageName, languages) => {
    return languages.indexOf(languageName) || '0'
}

export const checkUrl = (path) => {
    if (isRegistrationProcess()) {
        showCancelNewCustomer(path)
        return true
    }
    return false
}

export function compareIgnoreCase(a, b) {
    if (a === null) {
        return 1
    }
    if (b === null) {
        return -1
    }
    let r1 = isNaN(a) ? a.toLowerCase() : a.toString()
    let r2 = isNaN(b) ? b.toLowerCase() : b.toString()
    return r1.localeCompare(r2)
}

export const createLocaleWithMonths = (t, selectedLanguage) => {
    if (selectedLanguage === null) {
        selectedLanguage = 'en'
    }
    selectedLanguage = selectedLanguage.substring(0, 2)
    let locale = localeMap[selectedLanguage]
    locale = {
        ...locale,
        months: [
            t('general.month.1', { replace: ignoreKeyInHTML }),
            t('general.month.2', { replace: ignoreKeyInHTML }),
            t('general.month.3', { replace: ignoreKeyInHTML }),
            t('general.month.4', { replace: ignoreKeyInHTML }),
            t('general.month.5', { replace: ignoreKeyInHTML }),
            t('general.month.6', { replace: ignoreKeyInHTML }),
            t('general.month.7', { replace: ignoreKeyInHTML }),
            t('general.month.8', { replace: ignoreKeyInHTML }),
            t('general.month.9', { replace: ignoreKeyInHTML }),
            t('general.month.10', { replace: ignoreKeyInHTML }),
            t('general.month.11', { replace: ignoreKeyInHTML }),
            t('general.month.12', { replace: ignoreKeyInHTML }),
        ],
    }
    return locale
}

export const isCustomerInformationDataValid = (validationResult) => {
    const validStateOptions = [
        'firstName',
        'surname',
        'nickname',
        'dateOfBirth',
        'dialCode',
        'phoneNumber',
        'preferredLanguage',
    ]

    return (
        validStateOptions.every((item) => validationResult[item] === null) &&
        validationResult.address?.countryId === null
    )
}

export const getSessionId = () => {
    const sessionId = window.sessionStorage.getItem('x-session-id') || uuid4()
    // saving sessionId in case it was not present
    window.sessionStorage.setItem('x-session-id', sessionId)
    return sessionId
}

export const clearSessionId = () => {
    window.sessionStorage.removeItem('x-session-id')
}
