import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Page from '../../components/Wrappers/Page'
import TabNavigation from '../../components/TabNavigation'
import Journey from '../../components/Journey'
import UpdateOrderDetails from './UpdateOrderDetails'
import UpdateCustomerInformation from './UpdateCustomerInformation'
import TaskBox from '../../components/TaskBox'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { useSaveInputChange } from '../../hooks/form/useSaveInputChange'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import { useParams } from 'react-router'
import { Commissions, Customers } from '../../api'

const CommissionNumber = styled.div`
    position: absolute;
    top: 20px;
    right: 100px;
    color: #808285;
    font-size: 1.1em;
`

const tabs = [
    { label: 'general.customerJourney.title' },
    { label: 'general.order.title' },
    { label: 'general.customer.title' },
]

function CommissionDetail() {
    const { customerId } = useParams()
    const { setSearchParam } = useSaveInputChange()
    const [loading, setLoading] = useState(true)
    const [activeTab, setActiveTab] = useState(0)
    const [customer, setCustomer] = useState(null)
    const [statuses, setStatuses] = useState(null)
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)

    const getStatusesData = async (customerId) => {
        try {
            const response = await Customers.getCustomerStatuses(customerId)
            setStatuses(response?.data)
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        setSearchParam(null)
    }, [setSearchParam])

    useEffect(() => {
        if (customerId) {
            const loadData = async () => {
                try {
                    const customerResponse = await Commissions.getCommission(customerId)
                    setCustomer(customerResponse.data)
                    await getStatusesData(customerId)
                } catch (e) {}
                setLoading(false)
            }
            loadData()
        }
    }, [customerId])

    return (
        <ScrollTop>
            {(loading || !customer) && <Loading />}
            {!loading && (
                <Page
                    withBackLink={true}
                    backLinkUrl={'/customers'}
                    title={`${customer.customerInformation.firstName} ${customer.customerInformation.surname}`}
                >
                    <TabNavigation tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                    {activeTab === 0 && customer && statuses && (
                        <Journey
                            customer={customer}
                            statuses={statuses}
                            getStatusesData={getStatusesData}
                        />
                    )}
                    {activeTab === 1 && customer && statuses && (
                        <UpdateOrderDetails
                            orderDetails={customer.orderDetails}
                            requestedServices={customer.requestedServices}
                            customerId={customer.id}
                            statuses={statuses}
                            getStatusesData={getStatusesData}
                            registrationStartedDataLocked={customer.registrationStartedDataLocked}
                        />
                    )}
                    {activeTab === 2 && customer && (
                        <UpdateCustomerInformation customer={customer} />
                    )}
                    {customer.orderDetails.commissionNumber && (
                        <CommissionNumber>
                            {t('general.customer.commissionNo')}{' '}
                            {customer.orderDetails.commissionNumber}
                        </CommissionNumber>
                    )}
                    <TaskBox customer={customer} statuses={statuses} />
                </Page>
            )}
        </ScrollTop>
    )
}

export default WithHeader(CommissionDetail)
