export const customerStatuses = [
    'pdeProfileCreated',
    'seatIdAndAppDownloadInvitationSent',
    'seatIdCreated',
    'seatConnectAccountRegistered',
    // ident hidden MSP-47322
    // 'seatIdentVerificationStatus',
    'customerRegistrationComplete',
]

export const vehicleStatuses = [
    'consent',
    'commissionNumberComplete',
    'vinComplete',
    'deliveryDateComplete',
    'vehicleIsInVirtualGarage',
    'vehicleActivationStatus',
    'preferredServicePartnerSet',
    'dealerMaintenanceSchedulingEnablement',
    'vehicleJourneyComplete',
    'vehicleActivationRequestCancelled',
]
