import React from 'react'
import styled from 'styled-components'
import checkboxOn from '../../assets/images/checkbox-on.svg'
import checkboxOff from '../../assets/images/checkbox-off.svg'
import InputTooltip from '../Tooltip/InputTooltip'

const Box = styled.div`
    display: flex;
    width: 100%;
    margin: 0 0.5rem 1rem 0;
    padding: 0 0 0 1rem;
    justify-content: center;
    align-items: center;
    img {
        cursor: pointer;
    }
`
const Input = styled.input`
    cursor: pointer;
    margin-bottom: 1.25rem;
    display: none;
`

const Label = styled.label`
    cursor: pointer;
    color: #4a4a4a;
    margin-right: 0.6rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    ${({ isDisabled }) => isDisabled && 'opacity: 0.5; cursor: default;'}
    & img {
        ${({ isDisabled }) => isDisabled && 'opacity: 0.5; cursor: default;'}
    }
`

const LabelText = styled.label`
    cursor: pointer;
    color: #4a4a4a;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    ${({ isDisabled }) => isDisabled && 'opacity: 0.5; cursor: default;'}
    ${({ small }) => small && 'font-size: 14px;'}
    & img {
        ${({ isDisabled }) => isDisabled && 'opacity: 0.5; cursor: default;'}
    }
`

const MandatoryStar = styled.div`
    margin-right: 5px;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1;
    color: rgba(0, 0, 0, 0.54);

    :after {
        content: '*';
    }
`

export default function Checkbox({
    isDisabled,
    onClick,
    label,
    isChecked,
    tooltip,
    name,
    mandatory,
    showAlways,
    small,
}) {
    return (
        <Box>
            <Input
                name={'checkbox-' + name}
                id={'checkbox-' + name}
                disabled={isDisabled}
                type={'checkbox'}
            />
            <Label
                htmlFor={'checkbox-' + name}
                isDisabled={isDisabled}
                onClick={isDisabled ? undefined : onClick}
            >
                <img src={isChecked ? checkboxOn : checkboxOff} alt="checkbox" />
            </Label>
            {mandatory && <MandatoryStar />}
            <LabelText
                htmlFor={'checkbox-' + name}
                isDisabled={isDisabled}
                onClick={isDisabled ? undefined : onClick}
                small={small}
            >
                {label}
            </LabelText>
            {tooltip && (isDisabled || showAlways) && <InputTooltip text={tooltip} />}
        </Box>
    )
}
