import { saveAs } from 'file-saver'

export function saveToCsvFile(data, fileName) {
    let reader = new window.FileReader()
    reader.readAsDataURL(data)
    reader.onloadend = function () {
        let result = reader.result
        let base64data = result.substr(result.indexOf(',') + 1)
        const binaryString = window.atob(base64data)
        const contentType = 'text/csv'
        const blob = new Blob([window.atob(binaryString)], {
            type: contentType,
        })
        const iOSDeviceWithChrome =
            /CriOS/i.test(navigator.userAgent) && /iphone|ipod|ipad/i.test(navigator.userAgent)
        if (iOSDeviceWithChrome) {
            // blob in Chrome iOS is not fully supported  (https://support.google.com/chrome/thread/9516403),
            // workaround to fix downloading feature: by using FileReader (https://github.com/eligrey/FileSaver.js/issues/467#issuecomment-605596975)
            // but without predefined filename for save
            const fileReader = new FileReader()
            fileReader.onload = () => {
                if (typeof fileReader.result === 'string') {
                    const newWindow = window.open(fileReader.result)
                    setTimeout(() => {
                        newWindow.document.title = `CBA Report ${fileName}.csv`
                    }, 10)
                }
            }
            fileReader.readAsDataURL(blob)
        } else {
            saveAs(blob, `CBA Report ${fileName}.csv`)
        }
    }
}
