// commissions
export const GET_COMMISSIONS_STARTED = 'GET_COMMISSIONS_STARTED'
export const GET_COMMISSIONS_SUCCESS = 'GET_COMMISSIONS_SUCCESS'
export const GET_COMMISSIONS_ERROR = 'GET_COMMISSIONS_ERROR'

// customer
export const INIT_REGISTRATION_PROCESS = 'INIT_REGISTRATION_PROCESS'
export const GET_CUSTOMER_STARTED = 'GET_CUSTOMER_STARTED'
export const GET_CUSTOMER_STATUSES_STARTED = 'GET_CUSTOMER_STATUSES_STARTED'
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS'
export const GET_CUSTOMER_STATUSES_SUCCESS = 'GET_CUSTOMER_STATUSES_SUCCESS'
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR'
export const GET_CUSTOMER_STATUSES_ERROR = 'GET_CUSTOMER_STATUSES_ERROR'

export const DELETE_CUSTOMER_STARTED = 'DELETE_CUSTOMER_STARTED'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR'

export const ADD_CUSTOMER_INFO_FROM_GROUP_ID = 'ADD_CUSTOMER_INFO_FROM_GROUP_ID'

export const CREATE_CUSTOMER_STARTED = 'CREATE_CUSTOMER_STARTED'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR'

export const CLEAR_NEW_CUSTOMER = 'CLEAR_NEW_CUSTOMER'
export const SET_CUSTOMER_EMAIL = 'SET_CUSTOMER_EMAIL'
export const RESET_VALIDATION = 'RESET_VALIDATION'

// account check
export const CHECK_ACCOUNT_STARTED = 'CHECK_ACCOUNT_STARTED'
export const CHECK_ACCOUNT_SUCCESS = 'CHECK_ACCOUNT_SUCCESS'
export const CHECK_ACCOUNT_ERROR = 'CHECK_ACCOUNT_ERROR'
export const REMOVE_ACCOUNT_CHECK = 'REMOVE_ACCOUNT_CHECK'

// customer information
export const SAVE_CUSTOMER_INFORMATION = 'SAVE_CUSTOMER_INFORMATION'
export const SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD = 'SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD'
export const SAVE_CUSTOMER_INFORMATION_ERRORS = 'SAVE_CUSTOMER_INFORMATION_ERRORS'
export const UPDATE_CUSTOMER_INFORMATION_STARTED = 'UPDATE_CUSTOMER_INFORMATION_STARTED'
export const UPDATE_CUSTOMER_INFORMATION_SUCCESS = 'UPDATE_CUSTOMER_INFORMATION_SUCCESS'
export const UPDATE_CUSTOMER_INFORMATION_ERROR = 'UPDATE_CUSTOMER_INFORMATION_ERROR'
export const REMOVE_ADDRESS_FIELDS = 'REMOVE_ADDRESS_FIELDS'
export const ADD_ADDRESS_FIELDS = 'ADD_ADDRESS_FIELDS'
export const ADD_ADDRESS_FIELDS_ON_UPDATE = 'ADD_ADDRESS_FIELDS_ON_UPDATE'

// seat ident
export const SAVE_SEAT_IDENT = 'SAVE_SEAT_IDENT'
export const SAVE_SEAT_IDENT_ERRORS = 'SAVE_SEAT_IDENT_ERRORS'
export const UPDATE_SEAT_IDENT_STARTED = 'UPDATE_SEAT_IDENT_STARTED'
export const UPDATE_SEAT_IDENT_SUCCESS = 'UPDATE_SEAT_IDENT_SUCCESS'
export const UPDATE_SEAT_IDENT_ERROR = 'UPDATE_SEAT_IDENT_ERROR'

export const REMOVE_SEAT_IDENT_DATA = 'REMOVE_SEAT_IDENT_DATA'
export const ADD_SKIPPED_STEP = 'ADD_SKIPPED_STEP'
export const REMOVE_SKIPPED_STEP = 'REMOVE_SKIPPED_STEP'

// order details
export const SAVE_ORDER_DETAILS = 'SAVE_ORDER_DETAILS'
export const SAVE_ORDER_DETAILS_ERRORS = 'SAVE_ORDER_DETAILS_ERRORS'
export const UPDATE_ORDER_DETAILS_STARTED = 'UPDATE_ORDER_DETAILS_STARTED'
export const UPDATE_ORDER_DETAILS_SUCCESS = 'UPDATE_ORDER_DETAILS_SUCCESS'
export const UPDATE_ORDER_DETAILS_ERROR = 'UPDATE_ORDER_DETAILS_ERROR'

export const REMOVE_ORDER_DETAILS_ERRORS = 'REMOVE_ORDER_DETAILS_ERRORS'

// consents
export const SET_REQUESTED_SERVICES = 'SET_REQUESTED_SERVICES'
export const SET_IS_PAPER_CONSENT = 'SET_IS_PAPER_CONSENT'

// service partners
export const GET_PSP_STARTED = 'GET_PSP_STARTED'
export const GET_PSP_SUCCESS = 'GET_PSP_SUCCESS'
export const GET_PSP_ERROR = 'GET_PSP_ERROR'
export const REMOVE_PSP = 'REMOVE_PSP'
export const SET_PSP = 'SET_PSP'

// report
export const DOWNLOAD_REPORT_STARTED = 'DOWNLOAD_REPORT_STARTED'
export const DOWNLOAD_REPORT_SUCCESS = 'DOWNLOAD_REPORT_SUCCESS'
export const DOWNLOAD_REPORT_ERROR = 'DOWNLOAD_REPORT_ERROR'

// popups
export const SET_CREATE_NEW_CUSTOMER_ACTIVE = 'SET_CREATE_NEW_CUSTOMER_ACTIVE'
export const SET_CANCEL_NEW_CUSTOMER_ACTIVE = 'SET_CANCEL_NEW_CUSTOMER_ACTIVE'
export const SET_HAS_SEAT_IDENT_ACTIVE = 'SET_HAS_SEAT_IDENT_ACTIVE'
export const SET_DEALER_POPUP_ACTIVE = 'SET_DEALER_POPUP_ACTIVE'
export const SET_LOGOUT_FROM_GRP_ACTIVE = 'SET_LOGOUT_FROM_GRP_ACTIVE'
export const SET_ACCESS_TO_CREATE_PROCESS = 'SET_ACCESS_TO_CREATE_PROCESS'
export const SET_DELETE_PROFILE_ACTIVE = 'SET_DELETE_PROFILE_ACTIVE'

// search
export const SET_SEARCH_PARAM = 'SET_SEARCH_PARAM'

// options
export const GET_OPTIONS_STARTED = 'GET_OPTIONS_STARTED'
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS'
export const GET_OPTIONS_ERROR = 'GET_OPTIONS_ERROR'

// i18n
export const GET_TRANSLATIONS_STARTED = 'GET_TRANSLATIONS_STARTED'
export const GET_TRANSLATIONS_SUCCESS = 'GET_TRANSLATIONS_SUCCESS'
export const GET_ALL_TRANSLATIONS_SUCCESS = 'GET_ALL_TRANSLATIONS_SUCCESS'
export const GET_TRANSLATIONS_ERROR = 'GET_TRANSLATIONS_ERROR'

// filter
export const SET_FILTER_PARAMS = 'SET_FILTER_PARAMS'
export const RESET_FILTER_PARAMS = 'RESET_FILTER_PARAMS'

// language selector
export const SET_SELECTED_LANGUAGE = 'SET_SELECTED_LANGUAGE'

// authentication
export const REMOVE_CONTEXT_URI = 'REMOVE_CONTEXT_URI'
export const SET_CONTEXT_URI = 'SET_CONTEXT_URI'

export const SET_AVAILABILITY_STATUS = 'SET_AVAILABILITY_STATUS'
