import TextInput from '../../components/TextInput'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DatePicker from '../../components/Datepicker/DatePicker'
import { CancelButton, Label, PrimaryButton } from '../../components/Buttons'
import Checkbox from '../../components/Icons/Checkbox'
import { useTranslation } from 'react-i18next'
import { OrderDetailsFormValidator } from '../../util/validators'
import {
    journeyStatusValuesEnum,
    DEALER_ROLE,
    TRANSLATIONS_NAMESPACE,
    orderCancelledKey,
    ignoreKeyInHTML,
} from '../../constants/global'
import {
    FormCenteredField,
    FormCenteredRow,
    FormWrapperPadding,
} from '../../components/FormElements'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { INPUT_SHORT } from '../../constants/defaultFormValues'
import TextInfo from '../../components/TextInfo'
import { formatDateIntoYYYYMMDDWithDashes } from '../../util/formatters'
import RequiredBox from '../../components/RequiredBox'
import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core'
import { MediumWidthCard } from '../../components/Wrappers/MediumWithCard'
import { CardTitle } from '../../components/Wrappers/Card'
import ServicePartnerSearchInput from '../../components/ServicePartnerSearchInput/ServicePartnerSearchInput'
import CancelOrderPopUp from '../../components/PopUp/variants/CancelOrderPopUp'

const isFormValid = (validationErrors) => {
    const formProperties = ['brand', 'commissionNumber', 'vin', 'deliveryDate', 'psp']
    return formProperties.every((item) => validationErrors[item] === null)
}

export default function UpdateOrderDetails({
    orderDetails,
    requestedServices,
    customerId,
    statuses,
    getStatusesData,
    registrationStartedDataLocked,
}) {
    const { userInfo } = useSelector((state) => state.oidc)
    const [editMode, setEditMode] = useState(false)
    const [editableData, setEditableData] = useState(orderDetails)
    const { updateOrderDetails } = useUpdateCustomerActions()
    const [data, setData] = useState({ ...orderDetails })
    const [errors, setErrors] = useState({})
    const [isCancelOrderPopupOpen, setIsCancelOrderPopupOpen] = useState(false)

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const vehicleStatuses = statuses.vehicleJourneyStatuses[0]
    const formId = 'order-details'

    useEffect(() => {
        if (orderDetails?.commissionNumber === '') {
            setEditableData({})
        }
    }, [orderDetails])

    const handleEditMode = () => {
        if (!editMode && !data.dealer?.id) {
            setData((prevState) => ({
                ...prevState,
                dealer: {
                    id: userInfo.username,
                    firstName: userInfo.firstName,
                    lastName: userInfo.lastName,
                },
            }))
        }
        setEditMode(!editMode)
    }

    const handleChange = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const setPsp = (id, name) => {
        setData((prevState) => ({
            ...prevState,
            preferredServicePartnerId: id,
            preferredServicePartnerName: name,
        }))
    }

    const handleChangeDate = (date, dataKey) => {
        const convertedDate = isNaN(date) ? date : formatDateIntoYYYYMMDDWithDashes(date)
        setData((prevState) => ({
            ...prevState,
            [dataKey]: convertedDate,
        }))
    }

    const onCancelEditing = () => {
        handleEditMode()
        setData({ ...orderDetails })
    }

    const validate = useCallback(() => {
        const validationErrors = OrderDetailsFormValidator(data, requestedServices)
        setErrors(validationErrors)
        return validationErrors
    }, [data, requestedServices])

    const onSaveClick = async () => {
        const validationErrors = validate()
        setErrors(validationErrors)
        if (isFormValid(validationErrors)) {
            const response = await updateOrderDetails(vehicleStatuses.vehicleId, data)
            if (response?.body?.errorKey) {
                const errorPair = response.body.errorKey.split(':')
                setErrors({ ...errors, [errorPair[0]]: errorPair[1] })
            } else {
                handleEditMode()
                getStatusesData(customerId)
                setEditableData(data)
            }
        }
    }

    return (
        <MediumWidthCard>
            <CardTitle>{t('general.order.title')}</CardTitle>
            <FormWrapperPadding>
                <form noValidate autoComplete="off">
                    <FormCenteredRow>
                        <FormCenteredField size={1}>
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'salesPerson'}
                                label={t('general.order.salesPerson')}
                                value={
                                    data.dealer && data.dealer?.firstName && data.dealer?.lastName
                                        ? `${data.dealer.firstName} ${data.dealer.lastName}`
                                        : ''
                                }
                                fullWidth
                                disabled={true}
                            />
                        </FormCenteredField>
                        <FormCenteredField size={1}>
                            <Label shrink>{t(`general.order.brand.title`)}</Label>
                            <RadioGroup
                                row
                                name="brand"
                                value={data.brand || ''}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value="SEAT"
                                    control={<Radio />}
                                    label="SEAT"
                                    disabled={!editMode}
                                />
                                <FormControlLabel
                                    value="CUPRA"
                                    control={<Radio />}
                                    label="CUPRA"
                                    disabled={!editMode}
                                />
                            </RadioGroup>
                        </FormCenteredField>
                        <FormCenteredField size={1}>
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'commissionNumber'}
                                label={t('general.order.commission')}
                                value={data.commissionNumber || ''}
                                fullWidth
                                error={errors.commissionNumber}
                                disabled={
                                    !editMode ||
                                    (registrationStartedDataLocked &&
                                        editableData.commissionNumber !== undefined &&
                                        editableData.commissionNumber !== null &&
                                        editableData.commissionNumber !== '')
                                }
                                maxLength={6}
                                locked={
                                    editMode &&
                                    registrationStartedDataLocked &&
                                    editableData.commissionNumber !== undefined &&
                                    editableData.commissionNumber !== null &&
                                    editableData.commissionNumber !== ''
                                }
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField size={3}>
                            <TextInput
                                formId={formId}
                                handleChange={handleChange}
                                inputId={'vin'}
                                label={t('general.order.vin')}
                                value={data.vin || ''}
                                fullWidth
                                error={errors.vin}
                                disabled={
                                    !editMode ||
                                    orderDetails.connectCapabilityStatus ||
                                    (registrationStartedDataLocked &&
                                        editableData.vin !== undefined &&
                                        editableData.vin !== null &&
                                        editableData.vin !== '')
                                }
                                tooltipText={t('general.order.vin.tooltip')}
                                maxLength={INPUT_SHORT}
                                warningLabelText={
                                    orderDetails.vin &&
                                    orderDetails.connectCapabilityStatus === false &&
                                    orderDetails.dealer &&
                                    orderDetails.dealer.firstName
                                        ? t('general.order.capability.status')
                                        : undefined
                                }
                                locked={
                                    editMode &&
                                    registrationStartedDataLocked &&
                                    editableData.vin !== undefined &&
                                    editableData.vin !== null &&
                                    editableData.vin !== ''
                                }
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField size={2}>
                            <DatePicker
                                formId={formId}
                                inputId={'deliveryDate'}
                                value={data.deliveryDate}
                                handleChange={(date) => handleChangeDate(date, 'deliveryDate')}
                                label={t('general.order.deliveryDate')}
                                error={errors.deliveryDate}
                                disabled={!editMode}
                                oneOfDates={errors.oneOfDates}
                                tooltipText={t('general.order.deliveryDate.tooltip', {
                                    replace: ignoreKeyInHTML,
                                })}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField size={1}>
                            <ServicePartnerSearchInput
                                orderDetails={data}
                                setPsp={setPsp}
                                errors={errors}
                                editMode={editMode}
                                disabled={!editMode || !requestedServices.preferredServicePartner}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <Checkbox
                            label={t('general.order.dealerMaintenanceScheduling')}
                            name={'serviceScheduling'}
                            isDisabled={true}
                            isChecked={!!requestedServices.dealerMaintenanceScheduling}
                        />
                    </FormCenteredRow>
                    {editMode &&
                        vehicleStatuses.preferredServicePartnerSet ===
                            journeyStatusValuesEnum.COMPLETED && (
                            <FormCenteredRow>
                                <TextInfo message={t('general.order.serviceScheduling.complete')} />
                            </FormCenteredRow>
                        )}
                    {editMode && <RequiredBox withBottom />}
                    {userInfo &&
                        userInfo.role === DEALER_ROLE &&
                        !editMode &&
                        vehicleStatuses[orderCancelledKey]?.state !==
                            journeyStatusValuesEnum.FAILED && (
                            <Fragment>
                                <FormCenteredRow>
                                    <PrimaryButton
                                        label={t('general.button.order.update')}
                                        clickAction={handleEditMode}
                                    />
                                </FormCenteredRow>
                                <FormCenteredRow>
                                    <CancelButton
                                        label={t('general.button.order.cancel')}
                                        clickAction={() => setIsCancelOrderPopupOpen(true)}
                                    />
                                </FormCenteredRow>
                            </Fragment>
                        )}
                    {userInfo &&
                        userInfo.role === DEALER_ROLE &&
                        !editMode &&
                        vehicleStatuses[orderCancelledKey]?.state ===
                            journeyStatusValuesEnum.FAILED && (
                            <Fragment>
                                <FormCenteredRow>
                                    <PrimaryButton
                                        label={t('general.button.order.new')}
                                        clickAction={handleEditMode}
                                    />
                                </FormCenteredRow>
                            </Fragment>
                        )}
                    {editMode && (
                        <Fragment>
                            <FormCenteredRow>
                                <PrimaryButton
                                    label={t('general.save')}
                                    clickAction={onSaveClick}
                                />
                            </FormCenteredRow>
                            <FormCenteredRow>
                                <CancelButton
                                    label={t('general.button.cancel')}
                                    clickAction={onCancelEditing}
                                />
                            </FormCenteredRow>
                        </Fragment>
                    )}
                </form>
            </FormWrapperPadding>
            <CancelOrderPopUp
                vehicleId={vehicleStatuses.vehicleId}
                customerId={customerId}
                getStatusesData={getStatusesData}
                isOpen={isCancelOrderPopupOpen}
                setIsOpen={setIsCancelOrderPopupOpen}
            />
        </MediumWidthCard>
    )
}
