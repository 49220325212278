import React, { useCallback, useEffect, useState } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/TextInput'
import HorizontalStepper from '../../components/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/RegistrationNavigationButtons'
import { ORDER_DETAILS_STEP } from '../../constants/registrationConstants'
import { useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import DatePicker from '../../components/Datepicker/DatePicker'
import { OrderDetailsFormValidator } from '../../util/validators'
import { useTranslation } from 'react-i18next'
import ServicePartnerSearchInput from '../../components/ServicePartnerSearchInput/ServicePartnerSearchInput'
import { INPUT_SHORT } from '../../constants/defaultFormValues'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import {
    FormDatepicker,
    FormField,
    FormInputField,
    FormRow,
    FormTitle,
} from '../../components/FormElements'
import { useServicePartnersActions } from '../../hooks/actions/useServicePartnerActions'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { formatDateIntoYYYYMMDDWithDashes } from '../../util/formatters'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core'
import { PrimaryButton } from '../../components/Buttons'
import { useCustomerActions } from '../../hooks/actions/useCustomerActions'
import { disallowAccessToCreateProcess } from '../../actions/popupsActions'
import { UploadConsentDocument } from '../../components/PopUp/UploadConsentDocument'
import { ModifyConsents } from '../../components/PopUp/ModifyConsents'

const isFormValid = (validationErrors) => {
    const formProperties = ['commissionNumber', 'vin', 'deliveryDate', 'psp']
    return formProperties.every((item) => validationErrors[item] === null)
}

function CreateOrderDetails() {
    const { clearCustomer } = useUpdateCustomerActions()
    const customer = useSelector((state) => state.customer)
    const { orderDetails, requestedServices, isPaperConsent } = customer
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const { createCustomer } = useCustomerActions()

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()
    const { setPsp } = useServicePartnersActions()
    const { saveOrderDetailsField } = useUpdateCustomerActions()

    const formId = 'order-details'

    const [isUploadPopupOpen, setIsUploadPopupOpen] = useState(false)
    const [isConsentDocumentUploaded, setIsConsentDocumentUploaded] = useState(false)
    const [isModifyConsentPopupOpen, setIsModifyConsentPopupOpen] = useState(false)
    const [errors, setErrors] = useState({})

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        }
    }, [accessToCreateProcess, history])

    const createCustomerHandler = useCallback(() => {
        createCustomer(customer)
            .then((result) => {
                if (result?.body?.errorKey) {
                    const errorPair = result.body.errorKey.split(':')
                    setErrors({ ...errors, [errorPair[0]]: errorPair[1] })
                } else {
                    disallowAccessToCreateProcess()
                    history.push({
                        pathname: '/customers',
                    })
                    clearCustomer()
                }
            })
            .catch((err) => {
                console.error(err)
                disallowAccessToCreateProcess()
                history.push({
                    pathname: '/customers',
                })
                clearCustomer()
            })
    }, [clearCustomer, createCustomer, customer, errors, history, setErrors])

    const validate = () => {
        const validationErrors = OrderDetailsFormValidator(orderDetails, requestedServices)
        setErrors(validationErrors)
        return validationErrors
    }

    const handleChange = (event) => {
        event.persist()
        saveOrderDetailsField(event.target.name, event.target.value)
    }
    const handleChangeVIN = (event) => {
        event.persist()
        saveOrderDetailsField(event.target.name, event.target.value.toUpperCase())
    }

    const handleChangeDeliveryDate = (date) => {
        if (date instanceof Date) {
            const convertedDate = isNaN(date) ? date : formatDateIntoYYYYMMDDWithDashes(date)
            saveOrderDetailsField('deliveryDate', convertedDate)
        } else if (date === null) {
            saveOrderDetailsField('deliveryDate', date)
        }
    }

    const onNextClick = () => {
        const validationErrors = validate()
        if (isFormValid(validationErrors)) {
            createCustomerHandler()
        }
    }

    return (
        <ScrollTop>
            <MuiThemeProvider>
                <FormWrapper>
                    <HorizontalStepper activeStep={ORDER_DETAILS_STEP} />
                    <Panel>
                        <FormTitle>{t('general.order.title')}</FormTitle>
                        <form noValidate autoComplete="off">
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={handleChange}
                                        inputId={'commissionNumber'}
                                        label={t('general.order.commission')}
                                        value={orderDetails.commissionNumber}
                                        fullWidth
                                        error={errors.commissionNumber}
                                        maxLength={6}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormField>
                                <ServicePartnerSearchInput
                                    orderDetails={orderDetails}
                                    setPsp={setPsp}
                                    errors={errors}
                                    editMode
                                />
                            </FormField>
                            {isPaperConsent ? (
                                <FormField>
                                    <Box mb={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={true}
                                                    checked={
                                                        requestedServices?.dealerMaintenanceScheduling
                                                    }
                                                />
                                            }
                                            label={t('general.order.serviceScheduling')}
                                        />
                                    </Box>
                                </FormField>
                            ) : (
                                <Box mb={2}></Box>
                            )}
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={handleChangeVIN}
                                        inputId={'vin'}
                                        label={t('general.order.vin')}
                                        value={orderDetails.vin || ''}
                                        fullWidth
                                        maxLength={INPUT_SHORT}
                                        error={errors.vin}
                                        tooltipText={t('general.order.vin.tooltip')}
                                        mandatory={false}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormRow>
                                <FormDatepicker>
                                    <DatePicker
                                        formId={formId}
                                        inputId={'deliveryDate'}
                                        value={orderDetails.deliveryDate}
                                        handleChange={handleChangeDeliveryDate}
                                        label={t('general.order.deliveryDate')}
                                        error={errors.deliveryDate}
                                        oneOfDates={errors.oneOfDates}
                                        tooltipText={t('general.order.deliveryDate.tooltip', {
                                            replace: ignoreKeyInHTML,
                                        })}
                                    />
                                </FormDatepicker>
                            </FormRow>
                        </form>
                        <RequiredBox withTop />
                        <Box mt={4}>
                            <Grid container justifyContent={'center'} spacing={2}>
                                <Grid item>
                                    <PrimaryButton
                                        label={t('general.order.button.modifyConsent')}
                                        clickAction={() => setIsModifyConsentPopupOpen(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <PrimaryButton
                                        disabled={!isPaperConsent}
                                        label={t('general.order.button.uploadConsentDocument')}
                                        clickAction={() => setIsUploadPopupOpen(true)}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <RegistrationNavigationButtons
                            activeStep={ORDER_DETAILS_STEP}
                            onNextClick={onNextClick}
                            isNextDisabled={
                                customer.loading || (isPaperConsent && !isConsentDocumentUploaded)
                            }
                        />
                    </Panel>
                    <UploadConsentDocument
                        isOpen={isUploadPopupOpen}
                        setIsUploadPopupOpen={setIsUploadPopupOpen}
                        setIsConsentDocumentUploaded={setIsConsentDocumentUploaded}
                    />
                    <ModifyConsents
                        isOpen={isModifyConsentPopupOpen}
                        onCloseHandler={setIsModifyConsentPopupOpen}
                    />
                </FormWrapper>
            </MuiThemeProvider>
        </ScrollTop>
    )
}

export default WithHeader(CreateOrderDetails)
