import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import TextInput from '../../TextInput'
import {
    ButtonWithSuffixSuffix,
    ButtonWithSuffixWrapper,
    Label,
    PrimaryButton,
} from '../../Buttons'
import PopUp from '../PopUp'
import { closeCreateNewCustomer } from '../../../actions/popupsActions'
import React, { useEffect, useState } from 'react'
import { ignoreKeyInHTML, rqStatusMap, TRANSLATIONS_NAMESPACE } from '../../../constants/global'
import { INCORRECT_EMAIL, validateEmailCreateNewCustomerPopup } from '../../../util/validators'
import Input from '@material-ui/core/Input'
import { BeakIcon } from '../../Icons/BeakIcon'
import Select from '@material-ui/core/Select'
import styled from 'styled-components'
import MenuItem from '@material-ui/core/MenuItem'
import { Box, CircularProgress, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import InputTooltip from '../../Tooltip/InputTooltip'
import { Accounts, Availability } from '../../../api'
import { useUpdateCustomerActions } from '../../../hooks/actions/useUpdateCustomerActions'
import { useDispatchNotification } from '../../../hooks/useDispatchNotification'
import { NOTIFICATION_TYPE_ERROR, NOTIFICATION_TYPE_SUCCESS } from 'react-redux-notify'
import {
    CHECK_ACCOUNT_ERROR,
    CHECK_ACCOUNT_SUCCESS,
    SET_REQUESTED_SERVICES,
    SET_IS_PAPER_CONSENT,
    SET_AVAILABILITY_STATUS,
} from '../../../types/reduxActionTypes'
import { RequestedServicesForm } from '../../RequestedServicesForm'
import ConfigurationHolder from '../../../configurationHolder'

const Item = styled(MenuItem)`
    text-transform: none;
`

export default function CreateNewCustomerPopUp({
    emailValue,
    emailError,
    setEmailError,
    onCreateClick,
    changeValue,
}) {
    const [languages, setLanguages] = useState([])
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const { createNewCustomerActive } = useSelector((state) => state.popups)
    const { userInfo } = useSelector((state) => state.oidc)
    const { orderDetails, requestedServices } = useSelector((state) => state.customer)
    const { countries } = useSelector((state) => state.options)
    const [consentEmailRqStatus, setConsentEmailRqStatus] = useState(rqStatusMap.INITIAL)
    const [checkAccountForPrintRqStatus, setCheckAccountForPrintRqStatus] = useState(
        rqStatusMap.INITIAL
    )
    const [dispatchNotification] = useDispatchNotification()
    const dispatch = useDispatch()
    const { saveOrderDetailsField } = useUpdateCustomerActions()
    const [isConsentRequested, setIsConsentRequested] = useState(false)
    const [isPrintConsentsFormShown, setIsPrintConsentsFormShown] = useState(false)
    const [consentLanguage, setConsentLanguage] = useState('')
    const { clearCustomer } = useUpdateCustomerActions()

    const setBrand = (value) => {
        saveOrderDetailsField('brand', value)
    }

    useEffect(() => {
        const init = async () => {
            clearCustomer()
            dispatch({
                type: SET_AVAILABILITY_STATUS,
                payload: [],
            })
            try {
                const response = await Availability.getExternalSystemsAvailability()
                dispatch({
                    type: SET_AVAILABILITY_STATUS,
                    payload: response.data.filter((item) => item.status !== 'OK') || [],
                })
                // clear availability warnings automatically in 10 seconds (configurable)
                window.setTimeout(() => {
                    dispatch({
                        type: SET_AVAILABILITY_STATUS,
                        payload: [],
                    })
                }, ConfigurationHolder.getConfiguration()?.systemAvailabilityAlertDurationInMs || 10000)
            } catch (e) {
                console.error(e)
            }
        }
        init()
    }, [clearCustomer, dispatch])

    useEffect(() => {
        if (countries !== null && userInfo && userInfo.country !== null && countries.length > 0) {
            const dealerCountryOptions = countries.find((c) => c.countryCode === userInfo.country)
            setLanguages(dealerCountryOptions.languages)
            setConsentLanguage(dealerCountryOptions.defaultLanguage)
        }
    }, [countries, userInfo, languages])

    const handlePrint = (triggerPrint = false) => {
        setIsConsentRequested(true)
        // setting mandatory dataProcessing consent to true automatically alongside print/document preview action
        dispatch({
            type: SET_REQUESTED_SERVICES,
            payload: {
                ...requestedServices,
                dataProcessing: true,
            },
        })
        const paramsArray = [
            `language=${consentLanguage}`,
            `email=${emailValue}`,
            `preferredServicePartner=${requestedServices.preferredServicePartner.toString()}`,
            `dealerMaintenanceScheduling=${requestedServices.dealerMaintenanceScheduling.toString()}`,
            `seatIdent=${requestedServices.seatIdent.toString()}`,
        ]
        // TODO refactor to basic link maybe (global buttons refactor needed)?
        triggerPrint
            ? window.open(`/consent-documents?print=yes&${paramsArray.join('&')}`, '_blank')
            : window.open(`/consent-documents?${paramsArray.join('&')}`, '_blank')
        if (triggerPrint) {
            onCreateClick()
            dispatch({
                type: SET_IS_PAPER_CONSENT,
                payload: true,
            })
        }
    }

    const sendConsentEmail = async () => {
        setConsentEmailRqStatus(rqStatusMap.INITIAL)
        if (validateEmailCreateNewCustomerPopup(emailValue) === INCORRECT_EMAIL) {
            setEmailError(INCORRECT_EMAIL)
            return
        }

        setConsentEmailRqStatus(rqStatusMap.IN_PROGRESS)
        try {
            // checking if customer exists before sending consent email
            const checkCustomerResponse = await Accounts.checkAccount(emailValue)
            const checkCustomerResponseData = checkCustomerResponse?.data

            // account does not exist in CBA
            if (checkCustomerResponseData && !checkCustomerResponseData.cbaAccount) {
                try {
                    await Accounts.createConsentsDigital({
                        email: emailValue,
                        language: consentLanguage,
                        brand: orderDetails.brand,
                    })

                    setIsConsentRequested(true)
                    setConsentEmailRqStatus(rqStatusMap.SUCCESS)

                    dispatchNotification({
                        message: t('general.consentEmail.sent.success', {
                            replace: ignoreKeyInHTML,
                        }),
                        type: NOTIFICATION_TYPE_SUCCESS,
                        duration: 3000,
                        canDismiss: true,
                    })
                } catch (e) {
                    console.error(e)
                    setConsentEmailRqStatus(rqStatusMap.ERROR)
                    dispatchNotification({
                        message: t('general.consentEmail.sent.error', { replace: ignoreKeyInHTML }),
                        type: NOTIFICATION_TYPE_ERROR,
                        duration: 3000,
                        canDismiss: true,
                    })
                }
            } else {
                // dispatching CHECK_ACCOUNT_SUCCESS to trigger existing account flow
                dispatch({
                    type: CHECK_ACCOUNT_SUCCESS,
                    payload: {
                        accountCheck: checkCustomerResponseData,
                    },
                })
            }
        } catch (e) {
            dispatch({
                type: CHECK_ACCOUNT_ERROR,
            })
        }
    }

    const handlePrintConsentClick = async () => {
        if (validateEmailCreateNewCustomerPopup(emailValue) === INCORRECT_EMAIL) {
            setEmailError(INCORRECT_EMAIL)
            return
        }

        setCheckAccountForPrintRqStatus(rqStatusMap.IN_PROGRESS)

        try {
            // checking if customer exists before sending consent email
            const checkCustomerResponse = await Accounts.checkAccount(emailValue)
            const checkCustomerResponseData = checkCustomerResponse?.data

            // account does not exist in CBA
            if (checkCustomerResponseData && !checkCustomerResponseData.cbaAccount) {
                setIsPrintConsentsFormShown(true)
            } else {
                // dispatching CHECK_ACCOUNT_SUCCESS to trigger existing account flow
                dispatch({
                    type: CHECK_ACCOUNT_SUCCESS,
                    payload: {
                        accountCheck: checkCustomerResponseData,
                    },
                })
            }
        } catch (e) {
            dispatch({
                type: CHECK_ACCOUNT_ERROR,
            })
        }
        setCheckAccountForPrintRqStatus(rqStatusMap.INITIAL)
    }

    const content = (
        <>
            {!isPrintConsentsFormShown && (
                <>
                    <TextInput
                        inputId={'email'}
                        label={t('general.customer.create.popup.email')}
                        fullWidth={true}
                        value={emailValue}
                        handleChange={changeValue}
                        error={emailError}
                        noCheckmark={validateEmailCreateNewCustomerPopup(emailValue) !== null}
                    />
                    <Box sx={{ mb: 6 }}>
                        <Label shrink>{t('general.footer.language')}</Label>
                        <Select
                            displayEmpty={false}
                            value={consentLanguage}
                            onChange={(e) => {
                                e.persist()
                                setConsentLanguage(e.target.value)
                            }}
                            input={<Input />}
                            MenuProps={{}}
                            IconComponent={BeakIcon}
                            name={'consentLanguage'}
                        >
                            {languages.map((name) => (
                                <Item key={name} value={name}>
                                    {t('general.language.' + name.substring(0, 2))}
                                </Item>
                            ))}
                        </Select>
                    </Box>

                    <Box sx={{ mb: 6 }}>
                        <Label shrink>{t(`general.order.brand.title`)}</Label>
                        <RadioGroup
                            row
                            name="brand"
                            value={orderDetails.brand || ''}
                            onChange={(e) => setBrand(e.target.value)}
                        >
                            <FormControlLabel value="SEAT" control={<Radio />} label="SEAT" />
                            <FormControlLabel value="CUPRA" control={<Radio />} label="CUPRA" />
                        </RadioGroup>
                    </Box>
                    <Box>
                        <Grid container justifyContent={'center'}>
                            <Grid item>
                                <ButtonWithSuffixWrapper>
                                    <PrimaryButton
                                        variant={'contained'}
                                        clickAction={sendConsentEmail}
                                        label={t(`general.order.sendConsentEmail`)}
                                        disabled={
                                            !orderDetails.brand ||
                                            emailValue === '' ||
                                            consentEmailRqStatus === rqStatusMap.IN_PROGRESS
                                        }
                                    />
                                    <ButtonWithSuffixSuffix>
                                        {consentEmailRqStatus === rqStatusMap.IN_PROGRESS ? (
                                            <CircularProgress size={'24px'} />
                                        ) : (
                                            <InputTooltip
                                                text={t(`general.order.sendConsentEmail.tooltip`)}
                                            />
                                        )}
                                    </ButtonWithSuffixSuffix>
                                </ButtonWithSuffixWrapper>
                            </Grid>
                            <Grid item>
                                <ButtonWithSuffixWrapper>
                                    <PrimaryButton
                                        variant={'contained'}
                                        clickAction={handlePrintConsentClick}
                                        label={t(`general.order.printConsentDocuments`)}
                                        disabled={
                                            !orderDetails.brand || !emailValue || !consentLanguage
                                        }
                                    />
                                    <ButtonWithSuffixSuffix>
                                        {checkAccountForPrintRqStatus ===
                                        rqStatusMap.IN_PROGRESS ? (
                                            <CircularProgress size={'24px'} />
                                        ) : (
                                            <InputTooltip
                                                text={t(
                                                    `general.order.printConsentDocuments.tooltip`
                                                )}
                                            />
                                        )}
                                    </ButtonWithSuffixSuffix>
                                </ButtonWithSuffixWrapper>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            {isPrintConsentsFormShown && <RequestedServicesForm />}
        </>
    )

    const actions = (
        <>
            {!isPrintConsentsFormShown && (
                <PrimaryButton
                    label={t('general.customer.create.popup.proceed')}
                    clickAction={onCreateClick}
                    disabled={!isConsentRequested || emailValue === '' || !orderDetails.brand}
                />
            )}
            {isPrintConsentsFormShown && (
                <Grid container spacing={3} justifyContent={'center'}>
                    <Grid item>
                        <PrimaryButton
                            label={t('general.button.showConsent')}
                            clickAction={() => handlePrint(false)}
                        />
                    </Grid>
                    <Grid item>
                        <PrimaryButton
                            label={t('general.button.print')}
                            clickAction={() => handlePrint(true)}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )

    const popupData = {
        title: isPrintConsentsFormShown
            ? t('general.customer.requestedServices')
            : t('general.customer.create.popup.title'),
        type: 'CREATE_NEW_USER',
        actions,
        body: content,
    }

    return (
        <PopUp
            onClose={(e) => {
                if (e.target?.dataset?.clickawayCallback === 'preventClose') {
                    return
                }
                isPrintConsentsFormShown
                    ? setIsPrintConsentsFormShown(false)
                    : closeCreateNewCustomer()
            }}
            open={createNewCustomerActive}
            body={
                createNewCustomerActive ? (
                    popupData.body
                ) : (
                    <Grid container justifyContent={'center'}>
                        <CircularProgress />
                    </Grid>
                )
            }
            title={popupData.title}
            actions={createNewCustomerActive ? popupData.actions : null}
        />
    )
}
