import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import SortableTable from '../../components/SortableTable'
import TabNavigation from '../../components/TabNavigation'
import Page from '../../components/Wrappers/Page'
import FilterPopUp from '../../components/PopUp/variants/FilterPopUp'
import { useSelector } from 'react-redux'
import Arrow from '../../assets/images/icon-arrow-right-light.svg'
import { useTranslation } from 'react-i18next'
import { useGetCommissions } from '../../hooks/useGetCommissions'
import Loading from '../../components/Loading'
import { useSearchCommissions } from '../../hooks/useSearchCommissions'
import { TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { defaultTab } from '../../constants/defaultFormValues'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import { useHistory } from 'react-router'
import { WithHeader } from '../../components/Wrappers/WithHeader'

const FilterRow = styled.div`
    display: flex;
    position: relative;
`

const FilterButton = styled.div`
    display: flex;
    color: #4b4a4b;
    cursor: pointer;
    font-size: 14px;
`
const NoItemsFound = styled.div`
    margin-top: 50px;
    font-size: 18px;
    text-align: center;
`
const ArrowGreenDown = styled.div`
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transform: rotate(90deg);
    transition: 0.3s;
    margin-top: -2px;
     {
        ${({ active }) =>
            active &&
            `
            transform: rotate(-90deg);
        `}
    }
    img {
        width: 100%;
    }
`

const tabs = [
    {
        label: 'general.customers.tabs.all',
        tooltip: 'general.customers.tabs.all.tooltip',
        name: 'all',
    },
    {
        label: 'general.customers.tabs.carDelivery',
        tooltip: 'general.customers.tabs.carDelivery.tooltip',
        name: 'delivery',
    },
    {
        label: 'general.customers.tabs.vinAndDelivery',
        tooltip: 'general.customers.tabs.vinAndDelivery.tooltip',
        name: 'vin',
    },
    {
        label: 'general.customers.tabs.incomplete',
        tooltip: 'general.customers.tabs.incomplete.tooltip',
        name: 'incomplete',
    },
    {
        label: 'general.customers.tabs.failures',
        tooltip: 'general.customers.tabs.failures.tooltip',
        name: 'failed',
        suffixError: 'failedCommissionsCount',
    },
]

function CommissionsList() {
    const [openFilter, setOpenFilter] = useState(false)
    const { commissionsList, loading, searchParam, failedCommissionsCount } = useSelector(
        (state) => state.commissions
    )
    const userInfo = useSelector((state) => state.oidc.userInfo)
    const [activeTab, setActiveTab] = useState(0)
    const [getCommissions] = useGetCommissions()
    const [searchCommission] = useSearchCommissions()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const history = useHistory()

    const title =
        searchParam === null ? (
            t('general.header.dealershipCustomers')
        ) : (
            <>
                {t('general.dealershipCustomers.searchResults')} "{searchParam}"
            </>
        )

    useEffect(() => {
        if (userInfo === null) {
            history.push({
                pathname: '/not-logged-in',
            })
        }
        if (searchParam !== null) {
            searchCommission(searchParam)
        } else {
            getCommissions(tabs[activeTab].name, null, null, null)
        }
    }, [activeTab, getCommissions, history, searchCommission, searchParam, userInfo])

    const handleShowFilter = useCallback(() => {
        setOpenFilter(!openFilter)
    }, [openFilter])

    return (
        <ScrollTop>
            <Page title={title}>
                {searchParam === null && (
                    <FilterRow>
                        <TabNavigation
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            tabs={tabs}
                            errorSuffixes={{ failedCommissionsCount }}
                        />
                        {tabs[activeTab].name === defaultTab && (
                            <FilterButton onClick={handleShowFilter}>
                                {t('general.customers.table.filter')}
                                <ArrowGreenDown active={openFilter}>
                                    <img src={Arrow} alt={'Open filter'} />
                                </ArrowGreenDown>
                            </FilterButton>
                        )}
                        {openFilter && <FilterPopUp close={handleShowFilter} />}
                    </FilterRow>
                )}
                {commissionsList && !loading ? (
                    <SortableTable
                        data={commissionsList.map((item) => ({
                            ...item,
                            customerStatus: item.customerStatus
                                ? `general.statuses.customer.${item.customerStatus}`
                                : item.customerStatus,
                            vehicleStatus: item.vehicleStatus
                                ? `general.statuses.vehicle.${item.vehicleStatus}`
                                : item.vehicleStatus,
                        }))}
                    />
                ) : (
                    <Loading />
                )}
                {commissionsList.length === 0 && !loading && (
                    <NoItemsFound>{t('general.dealershipCustomers.noItemsFound')}</NoItemsFound>
                )}
            </Page>
        </ScrollTop>
    )
}

export default WithHeader(CommissionsList)
