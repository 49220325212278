import TextInput from '../../components/TextInput'
import React, { Fragment, useCallback, useState } from 'react'
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import { CancelButton, Label, PrimaryButton } from '../../components/Buttons'
import { useTranslation } from 'react-i18next'
import TextInfo from '../../components/TextInfo'
import { showDeleteProfilePopup } from '../../actions/popupsActions'
import {
    CONSENTS_TYPES,
    DEALER_ROLE,
    DPO_ROLE,
    ignoreKeyInHTML,
    TRANSLATIONS_NAMESPACE,
} from '../../constants/global'
import { getErrorText, validateCustomerInformation } from '../../util/validators'
import { getCustomerInformationOptions, isCustomerInformationDataValid } from '../../util/util'
import { defaultCountryId, INPUT_LONG } from '../../constants/defaultFormValues'
import {
    FormCenteredRow,
    FormCenteredField,
    FormWrapperPadding,
} from '../../components/FormElements'
import RequiredBox from '../../components/RequiredBox'
import { formatDateIntoYYYYMMDDWithDashes } from '../../util/formatters'
import DatePicker from '../../components/Datepicker/DatePicker'
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Select,
    Grid,
} from '@material-ui/core'
import { Customers } from '../../api'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'
import { BeakIcon } from '../../components/Icons/BeakIcon'
import { MediumWidthCard } from '../../components/Wrappers/MediumWithCard'
import { CardTitle } from '../../components/Wrappers/Card'
import { prepareCustomerInformationForUpdate } from '../../util/requestBodyCreators'
import { useDispatchNotification } from '../../hooks/useDispatchNotification'
import { updateErrorMessage, updateSuccessMessage } from '../../types/reduxNotificationTypes'

export default function UpdateCustomerInformation({ customer }) {
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { userInfo } = useSelector((state) => state.oidc)
    const {
        customerInformation,
        id: customerId,
        requestedServices,
        consentsType,
        seatGroupIdExistsFlag,
    } = customer

    const [data, setData] = useState({ ...customerInformation })
    const [editMode, setEditMode] = useState(false)
    const [errors, setErrors] = useState({})
    const [dispatchNotification] = useDispatchNotification()

    const saveCustomerInformationField = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }))
    }

    const onUpdateAddressInputHandle = (e) => {
        e.persist()
        setData((prevState) => ({
            ...prevState,
            address: {
                ...prevState.address,
                [e.target.name]: e.target.value,
            },
        }))
    }

    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const formId = 'customer-information'

    const { countryOptions } = getCustomerInformationOptions(countries, t)

    const handleChangeDate = (event) => {
        if (event instanceof Date) {
            const convertedDate = isNaN(event) ? event : formatDateIntoYYYYMMDDWithDashes(event)
            setData((prevState) => ({
                ...prevState,
                dateOfBirth: convertedDate,
            }))
        }
    }

    const handleEditMode = useCallback(() => {
        setEditMode(!editMode)
    }, [editMode])

    const onSaveClick = async () => {
        const validationErrors = validateCustomerInformation(data)
        setErrors(validationErrors)
        if (isCustomerInformationDataValid(validationErrors)) {
            try {
                const body = prepareCustomerInformationForUpdate(data)
                await Customers.updateCustomersInformationData(customerId, body)
                handleEditMode()
                dispatchNotification(updateSuccessMessage)
            } catch (e) {
                console.error(e)
                dispatchNotification(updateErrorMessage)
            }
        }
    }

    const onCancelEditing = () => {
        setData({ ...customerInformation })
        setErrors({})
    }

    const downloadConsentDocument = async () => {
        try {
            const response = await Customers.downloadConsentDocument(customerId)
            saveAs(response.response.body, 'consents.pdf')
        } catch (e) {
            console.error(e)
        }
    }

    const showConsentDocument = async () => {
        if (consentsType === CONSENTS_TYPES.PAPER) {
            await downloadConsentDocument()
        } else {
            window.open(`/consent-documents?customerId=${customerId}`, '_blank')
        }
    }

    return (
        <MediumWidthCard>
            <CardTitle>{t('general.customer.title')}</CardTitle>
            <FormWrapperPadding>
                <form noValidate autoComplete="off">
                    <FormCenteredRow>
                        <FormCenteredField>
                            <TextInput
                                formId={formId}
                                handleChange={saveCustomerInformationField}
                                inputId={'email'}
                                label={t('general.customer.email')}
                                value={data.email}
                                fullWidth
                                disabled
                                mandatory={editMode && !seatGroupIdExistsFlag}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField>
                            <TextInput
                                formId={formId}
                                handleChange={saveCustomerInformationField}
                                inputId={'firstName'}
                                label={t('general.customer.firstName')}
                                value={data.firstName || ''}
                                error={errors.firstName}
                                disabled={!editMode || seatGroupIdExistsFlag}
                                maxLength={INPUT_LONG}
                                mandatory={editMode && !seatGroupIdExistsFlag}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField>
                            <TextInput
                                formId={formId}
                                handleChange={saveCustomerInformationField}
                                inputId={'surname'}
                                label={t('general.customer.surname')}
                                value={data.surname || ''}
                                error={errors.surname}
                                disabled={!editMode || seatGroupIdExistsFlag}
                                maxLength={INPUT_LONG}
                                mandatory={editMode && !seatGroupIdExistsFlag}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField>
                            <TextInput
                                formId={formId}
                                handleChange={saveCustomerInformationField}
                                inputId={'nickname'}
                                label={t('general.customer.nickname')}
                                value={data.nickname || ''}
                                error={errors.nickname}
                                disabled={!editMode || seatGroupIdExistsFlag}
                                maxLength={INPUT_LONG}
                                mandatory={editMode && !seatGroupIdExistsFlag}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>
                    <FormCenteredRow>
                        <FormCenteredField>
                            <DatePicker
                                formId={formId}
                                inputId={'dateOfBirth'}
                                value={data.dateOfBirth}
                                handleChange={handleChangeDate}
                                label={t('general.ident.birthDate')}
                                error={errors.dateOfBirth}
                                disabled={!editMode || seatGroupIdExistsFlag}
                            />
                        </FormCenteredField>
                    </FormCenteredRow>

                    <Grid container spacing={3}>
                        <Grid item sm={6}>
                            <TextInput
                                formId={formId}
                                handleChange={(e) => {
                                    onUpdateAddressInputHandle(e)
                                    setData((prevState) => ({
                                        ...prevState,
                                        preferredLanguage: '',
                                    }))
                                }}
                                inputId={'countryId'}
                                label={t('general.customer.country')}
                                value={
                                    seatGroupIdExistsFlag && data.address?.countryId === '0'
                                        ? '-'
                                        : data.address?.countryId !== undefined &&
                                          data.address?.countryId !== defaultCountryId
                                        ? data.address?.countryId
                                        : defaultCountryId
                                }
                                options={
                                    seatGroupIdExistsFlag && data.address?.countryId === '0'
                                        ? undefined
                                        : countryOptions
                                }
                                error={errors.address && errors.address?.countryId}
                                disabled={!editMode || seatGroupIdExistsFlag}
                                mandatory={editMode && !seatGroupIdExistsFlag}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            {!!(data?.address?.countryId && countries) && (
                                <TextInput
                                    formId={formId}
                                    handleChange={saveCustomerInformationField}
                                    inputId={'preferredLanguage'}
                                    name={'preferredLanguage'}
                                    label={t('general.customer.preferredLanguage')}
                                    value={data.preferredLanguage || ''}
                                    options={[
                                        {
                                            value: '',
                                            label: t('general.select.choose'),
                                        },
                                        ...(countries
                                            .find(
                                                (country) =>
                                                    country.id.toString() ===
                                                    data.address?.countryId.toString()
                                            )
                                            ?.languages?.map((item) => ({
                                                value: item.toString(),
                                                label: t(`general.language.${item}`),
                                            })) || []),
                                    ]}
                                    error={errors.preferredLanguage}
                                    disabled={!editMode}
                                    valueKey={'value'}
                                    valueLabel={'label'}
                                    mandatory={editMode && !seatGroupIdExistsFlag}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item xs={3}>
                            <FormControl fullWidth>
                                <Label shrink>{t('general.customer.countryCode')}</Label>
                                <Select
                                    onChange={saveCustomerInformationField}
                                    name={'dialCode'}
                                    value={data.dialCode ?? ''}
                                    disabled={!editMode}
                                    IconComponent={() =>
                                        editMode ? <BeakIcon isInput={true} /> : <></>
                                    }
                                    error={!!errors.dialCode}
                                    renderValue={(value) => value}
                                >
                                    <MenuItem value={''}>{t('general.select.choose')}</MenuItem>
                                    {countryPhoneCodes
                                        .map((item) => ({
                                            ...item,
                                            translatedLabel: t(`general.country.${item.code}`, {
                                                replace: ignoreKeyInHTML,
                                            }),
                                        }))
                                        .sort((a, b) =>
                                            a.translatedLabel.localeCompare(
                                                b.translatedLabel,
                                                selectedLanguage.substring(0, 2)
                                            )
                                        )
                                        .map((item, index) => (
                                            <MenuItem value={item.dial_code} key={index}>
                                                {item.translatedLabel} ({item.dial_code})
                                            </MenuItem>
                                        ))}
                                </Select>
                                {errors.dialCode && (
                                    <FormHelperText error>
                                        {
                                            <>
                                                {t('general.customer.countryCode')}{' '}
                                                {t(getErrorText(errors.dialCode))}
                                            </>
                                        }
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Grid>
                        <Grid item xs={9}>
                            <TextInput
                                formId={formId}
                                handleChange={saveCustomerInformationField}
                                inputId={'phoneNumber'}
                                label={t('general.customer.phoneNumber')}
                                value={data.phoneNumber === null ? '-' : data.phoneNumber}
                                disabled={!editMode || seatGroupIdExistsFlag}
                                error={errors.phoneNumber}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{ mb: 6 }}>
                        <Label shrink>{t('general.customer.requestedServices')}</Label>
                        {/* tooltip will be added using key general.customer.requestedServices.tooltip */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={requestedServices.preferredServicePartner}
                                    disabled
                                />
                            }
                            label={t('general.order.psp')}
                        />

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={requestedServices.dealerMaintenanceScheduling}
                                    disabled
                                />
                            }
                            label={t('general.order.dms')}
                        />
                        {/* ident hidden MSP-47322*/}
                        {/*  <FormControlLabel*/}
                        {/*      control={<Checkbox checked={requestedServices.seatIdent} disabled />}*/}
                        {/*      label={t('general.ident.title')}*/}
                        {/*  />*/}
                    </Box>
                    {editMode && !seatGroupIdExistsFlag && <RequiredBox withBottom />}

                    <FormCenteredRow>
                        <PrimaryButton
                            style={{ width: 'auto' }}
                            label={t('general.button.showConsent')}
                            clickAction={showConsentDocument}
                        />
                    </FormCenteredRow>
                    {userInfo &&
                        userInfo.role === DEALER_ROLE &&
                        !editMode &&
                        !seatGroupIdExistsFlag && (
                            <FormCenteredRow>
                                <PrimaryButton
                                    label={t('general.button.order.update')}
                                    clickAction={setEditMode}
                                />
                            </FormCenteredRow>
                        )}
                    {!editMode && seatGroupIdExistsFlag && (
                        <FormCenteredRow>
                            <TextInfo message={t('general.customer.groupid.exists')} />
                        </FormCenteredRow>
                    )}
                    {editMode && !seatGroupIdExistsFlag && (
                        <Fragment>
                            <FormCenteredRow>
                                <PrimaryButton
                                    label={t('general.save')}
                                    clickAction={onSaveClick}
                                />
                            </FormCenteredRow>
                            <FormCenteredRow>
                                <CancelButton
                                    label={t('general.button.cancel')}
                                    clickAction={onCancelEditing}
                                />
                            </FormCenteredRow>
                        </Fragment>
                    )}
                    {userInfo &&
                        !editMode &&
                        (userInfo.role === DEALER_ROLE || userInfo.role === DPO_ROLE) && (
                            <FormCenteredRow>
                                <CancelButton
                                    label={t('general.customer.information.deleteCustomer')}
                                    clickAction={showDeleteProfilePopup}
                                />
                            </FormCenteredRow>
                        )}
                </form>
            </FormWrapperPadding>
        </MediumWidthCard>
    )
}
