import React, { useState, useEffect } from 'react'
import { MuiThemeProvider } from 'material-ui'
import TextInput from '../../components/TextInput'
import HorizontalStepper from '../../components/HorizontalStepper'
import RegistrationNavigationButtons from '../../components/RegistrationNavigationButtons'
import {
    CUSTOMER_INFORMATION_STEP,
    NAVIGATION_BUTTONS_CONFIGURATION,
} from '../../constants/registrationConstants'
import { useDispatch, useSelector } from 'react-redux'
import FormWrapper from '../../components/Wrappers/FormWrapper'
import Panel from '../../components/Wrappers/Panel'
import { useHistory } from 'react-router-dom'
import { getErrorText, validateCustomerInformation } from '../../util/validators'
import { showHasSeatIdentPopup } from '../../actions/popupsActions'
import { useTranslation } from 'react-i18next'
import { defaultCountryId, INPUT_LONG } from '../../constants/defaultFormValues'
import { ignoreKeyInHTML, TRANSLATIONS_NAMESPACE } from '../../constants/global'
import { getCustomerInformationOptions, isCustomerInformationDataValid } from '../../util/util'
import { SAVE_CUSTOMER_INFORMATION } from '../../types/reduxActionTypes'
import ScrollTop from '../../components/Wrappers/ScrollTop'
import {
    FormDatepicker,
    FormDateRow,
    FormField,
    FormInputField,
    FormTitle,
} from '../../components/FormElements'
import { useUpdateCustomerActions } from '../../hooks/actions/useUpdateCustomerActions'
import { countryPhoneCodes } from '../../constants/countryPhoneCodesConfiguration'
import { WithHeader } from '../../components/Wrappers/WithHeader'
import RequiredBox from '../../components/RequiredBox'
import DatePicker from '../../components/Datepicker/DatePicker'
import { formatDateIntoYYYYMMDDWithDashes } from '../../util/formatters'
import { setOcwRegistrationId } from '../../oidc/OidcSlice'
import { Label } from '../../components/Buttons'
import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@material-ui/core'
import { BeakIcon } from '../../components/Icons/BeakIcon'

function CreateCustomerInformation() {
    const { customerInformation } = useSelector((state) => state.customer)
    const { accountCheck } = useSelector((state) => state.account)
    const { countries, selectedLanguage } = useSelector((state) => state.options)
    const { accessToCreateProcess } = useSelector((state) => state.popups)
    const { userInfo } = useSelector((state) => state.oidc)
    const [errors, setErrors] = useState({})

    const customerInformationCountryId = customerInformation?.address?.countryId

    const dispatch = useDispatch()
    let history = useHistory()
    const { t } = useTranslation(TRANSLATIONS_NAMESPACE)
    const {
        saveCustomerInformationAddressField,
        saveCustomerInformationAddressItem,
        saveCustomerInformationField,
        saveCustomerInformationItem,
        saveSeatIdentField,
    } = useUpdateCustomerActions()

    const formId = 'customer-information'
    const buttonConfig = NAVIGATION_BUTTONS_CONFIGURATION[CUSTOMER_INFORMATION_STEP]

    const { countryOptions } = getCustomerInformationOptions(countries, t)

    useEffect(() => {
        if (!accessToCreateProcess) {
            history.push({
                pathname: '/customers',
            })
        }
    }, [accessToCreateProcess, history])

    useEffect(() => {
        dispatch(setOcwRegistrationId(null))

        // clearing preferred language if not in languages for prefilled country
        const customerCountryLanguages =
            customerInformationCountryId &&
            countries.find(
                (country) => country.id.toString() === customerInformationCountryId.toString()
            )?.languages
        if (
            !customerCountryLanguages ||
            !customerCountryLanguages.includes(customerInformation.preferredLanguage)
        ) {
            saveCustomerInformationItem('preferredLanguage', '')
        }
    }, [
        countries,
        customerInformationCountryId,
        customerInformation.preferredLanguage,
        dispatch,
        saveCustomerInformationItem,
    ])

    // prefilling data from dealers userinfo
    useEffect(() => {
        if (!customerInformationCountryId) {
            const dealerCountry =
                userInfo.country && countries.find((item) => userInfo.country === item.countryCode)

            dealerCountry && saveCustomerInformationAddressItem('countryId', dealerCountry.id)

            if (!customerInformation.preferredLanguage) {
                saveCustomerInformationItem('preferredLanguage', dealerCountry.defaultLanguage)
            }
            if (!customerInformation.dialCode) {
                const dealerCountryDialCode = countryPhoneCodes.find(
                    (item) => item.code === dealerCountry.countryCode
                )?.dial_code
                dealerCountryDialCode &&
                    saveCustomerInformationItem('dialCode', dealerCountryDialCode)
            }
        }
    }, [
        countries,
        customerInformationCountryId,
        customerInformation.dialCode,
        customerInformation.preferredLanguage,
        saveCustomerInformationAddressItem,
        saveCustomerInformationItem,
        userInfo.country,
    ])

    const handleChangeDate = (event) => {
        if (event instanceof Date) {
            const convertedDate = isNaN(event) ? event : formatDateIntoYYYYMMDDWithDashes(event)
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION,
                payload: {
                    name: 'dateOfBirth',
                    value: convertedDate,
                },
            })
        }
    }

    const onNextClick = () => {
        // no validation for group ID data
        if (accountCheck.groupIdAccount) {
            history.push({
                pathname: buttonConfig.nextButtonUrl,
            })
        } else {
            const validationErrors = validateCustomerInformation(customerInformation)
            setErrors(validationErrors)
            if (isCustomerInformationDataValid(validationErrors)) {
                if (accountCheck !== null) {
                    if (accountCheck.skodaIdAccount) {
                        showHasSeatIdentPopup()
                    } else {
                        const selectedCountry = countries.find(
                            (c) => c.id === customerInformationCountryId
                        )
                        if (selectedCountry) {
                            countryPhoneCodes.forEach((c, i) => {
                                if (c.code === selectedCountry.countryCode) {
                                    saveSeatIdentField(
                                        'countryOfIssueCode',
                                        countryPhoneCodes[i].code
                                    )
                                }
                            })
                        }
                        history.push({
                            pathname: buttonConfig.nextButtonUrl,
                        })
                    }
                }
            }
        }
    }

    return (
        <ScrollTop>
            <MuiThemeProvider>
                <FormWrapper>
                    <HorizontalStepper activeStep={CUSTOMER_INFORMATION_STEP} />
                    <Panel>
                        <FormTitle>{t('general.customer.title')}</FormTitle>
                        <form noValidate autoComplete="off">
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={saveCustomerInformationField}
                                        inputId={'email'}
                                        label={t('general.customer.email')}
                                        value={customerInformation.email}
                                        fullWidth
                                        error={errors.email}
                                        disabled
                                        tooltipText={t('general.customer.email.tooltip')}
                                        mandatory={!accountCheck?.connectAccount}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={saveCustomerInformationField}
                                        inputId={'firstName'}
                                        label={t('general.customer.firstName')}
                                        value={customerInformation.firstName}
                                        fullWidth
                                        error={errors.firstName}
                                        disabled={accountCheck?.connectAccount}
                                        maxLength={INPUT_LONG}
                                        mandatory={!accountCheck?.connectAccount}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={saveCustomerInformationField}
                                        inputId={'surname'}
                                        label={t('general.customer.surname')}
                                        value={customerInformation.surname}
                                        fullWidth
                                        error={errors.surname}
                                        disabled={accountCheck?.connectAccount}
                                        maxLength={INPUT_LONG}
                                        mandatory={!accountCheck?.connectAccount}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormField>
                                <FormInputField>
                                    <TextInput
                                        formId={formId}
                                        handleChange={saveCustomerInformationField}
                                        inputId={'nickname'}
                                        label={t('general.customer.nickname')}
                                        value={
                                            customerInformation?.nickname
                                                ? customerInformation.nickname.toLocaleUpperCase()
                                                : ''
                                        }
                                        disabled={accountCheck?.connectAccount}
                                        maxLength={INPUT_LONG}
                                        error={errors.nickname}
                                        mandatory={!accountCheck?.connectAccount}
                                    />
                                </FormInputField>
                            </FormField>
                            <FormDateRow>
                                <FormDatepicker>
                                    <DatePicker
                                        formId={formId}
                                        inputId={'dateOfBirth'}
                                        value={customerInformation.dateOfBirth}
                                        handleChange={handleChangeDate}
                                        label={t('general.ident.birthDate')}
                                        error={errors.dateOfBirth}
                                        disabled={accountCheck?.connectAccount}
                                    />
                                </FormDatepicker>
                            </FormDateRow>

                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <TextInput
                                        formId={formId}
                                        handleChange={(e) => {
                                            saveCustomerInformationAddressField(e)
                                            saveCustomerInformationItem('preferredLanguage', '')
                                        }}
                                        inputId={'countryId'}
                                        name={'countryId'}
                                        label={t('general.customer.country')}
                                        value={
                                            accountCheck?.connectAccount &&
                                            customerInformationCountryId === ''
                                                ? '-'
                                                : customerInformation.address?.countryId ||
                                                  defaultCountryId
                                        }
                                        options={
                                            accountCheck?.connectAccount &&
                                            customerInformation.country === ''
                                                ? undefined
                                                : countryOptions
                                        }
                                        error={errors.address && errors.address?.countryId}
                                        disabled={accountCheck?.connectAccount}
                                        mandatory={!accountCheck?.connectAccount}
                                    />
                                </Grid>
                                <Grid item sm={6}>
                                    {!!(customerInformationCountryId && countries) && (
                                        <TextInput
                                            formId={formId}
                                            handleChange={saveCustomerInformationField}
                                            inputId={'preferredLanguage'}
                                            name={'preferredLanguage'}
                                            label={t('general.customer.preferredLanguage')}
                                            value={customerInformation.preferredLanguage || ''}
                                            options={
                                                accountCheck?.connectAccount
                                                    ? undefined
                                                    : [
                                                          {
                                                              value: '',
                                                              label: t('general.select.choose'),
                                                          },
                                                          ...(countries
                                                              .find(
                                                                  (country) =>
                                                                      country.id.toString() ===
                                                                      customerInformation.address?.countryId.toString()
                                                              )
                                                              ?.languages?.map((item) => ({
                                                                  value: item.toString(),
                                                                  label: t(
                                                                      `general.language.${item}`
                                                                  ),
                                                              })) || []),
                                                      ]
                                            }
                                            error={errors.preferredLanguage}
                                            disabled={accountCheck?.connectAccount}
                                            mandatory={!accountCheck?.connectAccount}
                                            valueKey={'value'}
                                            valueLabel={'label'}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                            <Grid container spacing={1}>
                                {!accountCheck?.connectAccount && (
                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <Label shrink>
                                                {t('general.customer.countryCode')}
                                            </Label>
                                            <Select
                                                onChange={saveCustomerInformationField}
                                                name={'dialCode'}
                                                value={customerInformation.dialCode ?? ''}
                                                IconComponent={() => <BeakIcon isInput={true} />}
                                                disabled={accountCheck?.connectAccount}
                                                error={!!errors.dialCode}
                                                renderValue={(value) => value}
                                            >
                                                <MenuItem value={''}>
                                                    {t('general.select.choose')}
                                                </MenuItem>
                                                {countryPhoneCodes
                                                    .map((item) => ({
                                                        ...item,
                                                        translatedLabel: t(
                                                            `general.country.${item.code}`,
                                                            { replace: ignoreKeyInHTML }
                                                        ),
                                                    }))
                                                    .sort((a, b) =>
                                                        a.translatedLabel.localeCompare(
                                                            b.translatedLabel,
                                                            selectedLanguage.substring(0, 2)
                                                        )
                                                    )
                                                    .map((item, index) => (
                                                        <MenuItem
                                                            value={item.dial_code}
                                                            key={index}
                                                        >
                                                            {item.translatedLabel} ({item.dial_code}
                                                            )
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                            {errors.dialCode && (
                                                <FormHelperText error>
                                                    {
                                                        <>
                                                            {t('general.customer.countryCode')}{' '}
                                                            {t(getErrorText(errors.dialCode))}
                                                        </>
                                                    }
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>
                                )}
                                <Grid item xs={9}>
                                    <TextInput
                                        formId={formId}
                                        handleChange={saveCustomerInformationField}
                                        inputId={'phoneNumber'}
                                        label={t('general.customer.phoneNumber')}
                                        value={
                                            customerInformation.phoneNumber === null
                                                ? ''
                                                : customerInformation.phoneNumber
                                        }
                                        error={errors.phoneNumber}
                                        disabled={accountCheck?.connectAccount}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                        {!accountCheck?.connectAccount && <RequiredBox withTop />}
                        <RegistrationNavigationButtons
                            activeStep={CUSTOMER_INFORMATION_STEP}
                            onNextClick={onNextClick}
                        />
                    </Panel>
                </FormWrapper>
            </MuiThemeProvider>
        </ScrollTop>
    )
}

export default WithHeader(CreateCustomerInformation)
