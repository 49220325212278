import { NONE } from '../constants/defaultFormValues'
import { countryPhoneCodes } from '../constants/countryPhoneCodesConfiguration'
import { isValidPhoneNumber } from 'libphonenumber-js/max'

export const EMPTY = 'empty'
export const NOT_NUMBER = 'isNotNumber'
export const INVALID = 'invalid'
export const INVALID_DATE = 'invalid_date'
export const INVALID_PHONE = 'invalid_phone'
export const INCORRECT_EMAIL = 'incorrect_email'
export const PAST = 'past'
export const FUTURE = 'future'
export const DATE_OF_BIRTH = 'date_of_birth'
export const PSP = 'psp'

export function getErrorText(errorCode) {
    switch (errorCode) {
        case EMPTY:
            return 'general.validation.empty'
        case INVALID:
            return 'general.validation.invalid'
        case NOT_NUMBER:
            return 'general.validation.isNotNumber'
        case PAST:
            return 'general.validation.past'
        case FUTURE:
            return 'general.validation.future'
        case DATE_OF_BIRTH:
            return 'general.validation.birth'
        case PSP:
            return 'general.validation.psp'
        case INVALID_DATE:
            return 'general.validation.invalid'
        case INVALID_PHONE:
            return 'general.validation.phone'
        case INCORRECT_EMAIL:
            return 'general.customer.create.popup.incorrectEmail'
        default:
            return errorCode
    }
}

const validateLanguage = (language) => {
    return !language || language === NONE ? EMPTY : null
}

const validateEmail = (value) => {
    if (value === '') {
        return EMPTY
    }
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase()) ? null : INVALID
}

const validateVin = (value) => {
    if (value === '' || value === null) {
        return null
    }
    const re = new RegExp('^[A-HJ-NPR-Z0-9]{13}[0-9]{4}$')
    return re.test(String(value).toUpperCase()) ? null : INVALID
}

const validateSelect = (value, isMandatory) => {
    if (!value || value === '0') {
        return isMandatory ? EMPTY : null
    }
    return parseInt(value) > 0 ? null : INVALID
}

const validateCountryCode = (value, isMandatory) => {
    if (value === null || value === undefined) {
        return isMandatory ? EMPTY : null
    }
    return countryPhoneCodes.find((c) => c.code === value) ? null : INVALID
}

const validateString = (value) => {
    if (!value || value === '') {
        return EMPTY
    }
    return value.length > 0 ? null : INVALID
}

const validateNumbers = (value, minLength, maxLength) => {
    if (!value) {
        return EMPTY
    }
    const re =
        minLength !== undefined
            ? new RegExp('^[0-9]{' + minLength + ',' + maxLength + '}$')
            : /^[0-9]*$/
    return re.test(String(value).toLowerCase()) ? null : NOT_NUMBER
}

// checks validity of phone number including country dial code prefix
// regex is not sufficient - we need real numbers validation
// because external systems require an existing number
const validatePhone = (phoneNumberWithPrefix) => {
    if (!phoneNumberWithPrefix) {
        return null
    }

    return isValidPhoneNumber(phoneNumberWithPrefix) ? null : INVALID_PHONE
}

const validateDate = (value, period, yearsInPast) => {
    if (!value) {
        return EMPTY
    }
    const date = new Date(value)
    if (isNaN(date.getTime())) {
        return INVALID_DATE
    } else {
        if (date.getFullYear() >= 2100 || date.getFullYear() <= 1900) {
            return INVALID_DATE
        }
        if (PAST === period) {
            const nowEod = new Date().setHours(23, 59, 59, 999)
            if (yearsInPast !== undefined) {
                const addYearsInPast = new Date(date).setFullYear(date.getFullYear() + yearsInPast)
                return addYearsInPast - nowEod < 0 ? null : DATE_OF_BIRTH
            } else {
                return Date.parse(value) - nowEod < 0 ? null : PAST
            }
        } else if (FUTURE === period) {
            const now = new Date().setHours(0, 0, 0, 0)
            return Date.parse(value) - now >= 0 ? null : FUTURE
        }
        return null
    }
}

export function CustomerInformationFormValidator(customerInformation) {
    const validationErrors = customerInformation.errors
    return {
        ...validationErrors,
        email: validateEmail(customerInformation.email),
        firstName: validateString(customerInformation.firstName),
        surname: validateString(customerInformation.surname),
        nickname: validateString(customerInformation.nickname),
        dateOfBirth: customerInformation.dateOfBirth
            ? validateDate(customerInformation.dateOfBirth, PAST, 16)
            : null,
        dialCode:
            customerInformation.dialCode && customerInformation.phoneNumber
                ? validateString(customerInformation.dialCode)
                : null,
        phoneNumber:
            customerInformation.phoneNumber && customerInformation.dialCode
                ? validatePhone(`${customerInformation.dialCode}${customerInformation.phoneNumber}`)
                : null,
        address: {
            countryId: validateSelect(customerInformation?.address?.countryId, true),
        },
        preferredLanguage: validateLanguage(customerInformation.preferredLanguage),
    }
}

export function SeatIdentFormValidator(seatIdent, originData) {
    const validationErrors = seatIdent.errors

    const touched =
        originData &&
        (seatIdent.licenseNumber !== originData.licenseNumber ||
            seatIdent.issueDate !== originData.issueDate ||
            seatIdent.expirationDate !== originData.expirationDate ||
            seatIdent.countryOfIssueCode !== originData.countryOfIssueCode ||
            seatIdent.expirationDateCheckbox !== originData.expirationDateCheckbox)
    if (touched || originData === null) {
        return {
            ...validationErrors,
            licenseNumber: validateString(seatIdent.licenseNumber),
            issueDate: validateDate(seatIdent.issueDate, PAST, undefined),
            expirationDate: !seatIdent.expirationDateCheckbox
                ? validateDate(seatIdent.expirationDate, FUTURE, undefined)
                : null,
            countryOfIssueCode: validateCountryCode(seatIdent.countryOfIssueCode, true),
        }
    } else {
        return {
            ...validationErrors,
            licenseNumber:
                seatIdent.licenseNumber === originData.licenseNumber
                    ? validationErrors.licenseNumber
                    : validateString(seatIdent.licenseNumber),
            issueDate:
                seatIdent.issueDate === originData.issueDate
                    ? validationErrors.issueDate
                    : validateDate(seatIdent.issueDate, PAST, undefined),
            expirationDate:
                seatIdent.expirationDate === originData.expirationDate
                    ? validationErrors.expirationDate
                    : !seatIdent.expirationDateCheckbox
                    ? validateDate(seatIdent.expirationDate, FUTURE, undefined)
                    : null,
            countryOfIssueCode:
                seatIdent.countryOfIssueCode === originData.countryOfIssueCode
                    ? validationErrors.countryOfIssueCode
                    : validateCountryCode(seatIdent.countryOfIssueCode, true),
        }
    }
}

export function OrderDetailsFormValidator(orderDetails, requestedServices = null) {
    const validationErrors = orderDetails.errors

    const deliveryDate = validateDate(orderDetails.deliveryDate, FUTURE, undefined)
    let psp = null

    if (
        requestedServices?.preferredServicePartner &&
        orderDetails.preferredServicePartnerId === null
    ) {
        psp = EMPTY
    } else if (orderDetails.preferredServicePartnerId === null && orderDetails.serviceScheduling) {
        psp = PSP
    }

    return {
        ...validationErrors,
        brand: validateString(orderDetails.brand),
        commissionNumber:
            validateNumbers(orderDetails.commissionNumber, 6, 6) === NOT_NUMBER ? NOT_NUMBER : null,
        vin: validateVin(orderDetails.vin),
        deliveryDate: deliveryDate === EMPTY ? null : deliveryDate,
        psp,
    }
}

export const validateEmailCreateNewCustomerPopup = (value) => {
    if (value === '') {
        return INCORRECT_EMAIL
    }
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase()) ? null : INCORRECT_EMAIL
}

export const validateCustomerInformation = (data) => {
    return CustomerInformationFormValidator(data)
}
