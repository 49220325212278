import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { useDispatchNotification } from '../useDispatchNotification'
import { Customers, Vehicles } from '../../api'
import {
    CLEAR_NEW_CUSTOMER,
    SAVE_CUSTOMER_INFORMATION,
    SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD,
    SAVE_CUSTOMER_INFORMATION_ERRORS,
    SAVE_ORDER_DETAILS,
    SAVE_ORDER_DETAILS_ERRORS,
    SAVE_SEAT_IDENT,
    SAVE_SEAT_IDENT_ERRORS,
    UPDATE_ORDER_DETAILS_ERROR,
    UPDATE_ORDER_DETAILS_STARTED,
    UPDATE_ORDER_DETAILS_SUCCESS,
    UPDATE_SEAT_IDENT_ERROR,
    UPDATE_SEAT_IDENT_STARTED,
    UPDATE_SEAT_IDENT_SUCCESS,
} from '../../types/reduxActionTypes'
import { updateErrorMessage, updateSuccessMessage } from '../../types/reduxNotificationTypes'
import {
    prepareOrderDetailsForUpdate,
    prepareSeatIdentForUpdate,
} from '../../util/requestBodyCreators'
import { useCustomerActions } from './useCustomerActions'

export function useUpdateCustomerActions() {
    const dispatch = useDispatch()
    const [dispatchNotification] = useDispatchNotification()
    const { getCustomerStatuses } = useCustomerActions()

    const updateOrderDetails = useCallback(
        async (vehicleId, orderDetails) => {
            dispatch({
                type: UPDATE_ORDER_DETAILS_STARTED,
            })
            const body = prepareOrderDetailsForUpdate(orderDetails)
            return await Vehicles.updateVehicleOrderData(vehicleId, body)
                .then((response) => {
                    dispatch({
                        type: UPDATE_ORDER_DETAILS_SUCCESS,
                    })
                    dispatchNotification(updateSuccessMessage)
                    return response
                })
                .catch((error) => {
                    console.error(error)
                    dispatch({
                        type: UPDATE_ORDER_DETAILS_ERROR,
                    })
                    dispatchNotification(updateErrorMessage)
                    return error
                })
        },
        [dispatch, dispatchNotification]
    )

    const updateSeatIdent = useCallback(
        async (customerId, seatIdent) => {
            dispatch({
                type: UPDATE_SEAT_IDENT_STARTED,
            })
            const body = prepareSeatIdentForUpdate(seatIdent)
            return await Customers.updateCustomersIdentData(customerId, body)
                .then(() => {
                    dispatch({
                        type: UPDATE_SEAT_IDENT_SUCCESS,
                    })
                    dispatchNotification(updateSuccessMessage)
                    getCustomerStatuses(customerId)
                })
                .catch((error) => {
                    console.log(error)
                    dispatch({
                        type: UPDATE_SEAT_IDENT_ERROR,
                    })
                    dispatchNotification(updateErrorMessage)
                })
        },
        [dispatch, dispatchNotification, getCustomerStatuses]
    )

    const saveOrderDetailsField = useCallback(
        (name, value) => {
            dispatch({
                type: SAVE_ORDER_DETAILS,
                payload: {
                    name,
                    value,
                    originData: null,
                },
            })
        },
        [dispatch]
    )

    const saveSeatIdentField = useCallback(
        (name, value) => {
            dispatch({
                type: SAVE_SEAT_IDENT,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const clearCustomer = useCallback(() => {
        dispatch({
            type: CLEAR_NEW_CUSTOMER,
        })
    }, [dispatch])

    const saveCustomerInformationField = useCallback(
        (event) => {
            event.persist()
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION,
                payload: {
                    name: event.target.name,
                    value: event.target.value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationItem = useCallback(
        (name, value) => {
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationAddressField = useCallback(
        (event) => {
            event.persist()
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD,
                payload: {
                    name: event.target.name,
                    value: event.target.value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationAddressItem = useCallback(
        (name, value) => {
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION_ADDRESS_FIELD,
                payload: {
                    name,
                    value,
                },
            })
        },
        [dispatch]
    )

    const saveCustomerInformationErrors = useCallback(
        (validationErrors) => {
            dispatch({
                type: SAVE_CUSTOMER_INFORMATION_ERRORS,
                payload: {
                    value: validationErrors,
                },
            })
        },
        [dispatch]
    )

    const saveOrderDetailsErrors = useCallback(
        (validationErrors) => {
            dispatch({
                type: SAVE_ORDER_DETAILS_ERRORS,
                payload: {
                    value: validationErrors,
                },
            })
        },
        [dispatch]
    )

    const saveSeatIdentErrors = useCallback(
        (validationErrors) => {
            dispatch({
                type: SAVE_SEAT_IDENT_ERRORS,
                payload: {
                    value: validationErrors,
                },
            })
        },
        [dispatch]
    )

    const savePreferredLanguage = (lang) =>
        dispatch({
            type: SAVE_CUSTOMER_INFORMATION,
            payload: {
                name: 'preferredLanguage',
                value: lang,
            },
        })

    return {
        updateOrderDetails,
        updateSeatIdent,
        saveOrderDetailsField,
        saveSeatIdentField,
        clearCustomer,
        saveCustomerInformationField,
        saveCustomerInformationItem,
        saveCustomerInformationAddressField,
        saveCustomerInformationAddressItem,
        saveCustomerInformationErrors,
        saveOrderDetailsErrors,
        saveSeatIdentErrors,
        savePreferredLanguage,
    }
}
